import Vue from 'vue'
import {Message} from 'element-ui'
import axios from 'axios'
import router from '@/router'
import Cookies from 'js-cookie'

const request = axios.create({
   baseURL: process.env.VUE_APP_API_URL,
   timeout: 100000
})

request.interceptors.request.use(req=>{
   req.headers.token=window.sessionStorage.getItem('token')   
   return req
},err=>{
   return Promise.reject(err);
})
request.interceptors.response.use(res=>{
   if(res.data.code==900){
      Message({
         type:'warning',
         message:res.data.message
      })
      router.replace('/login')
      window.sessionStorage.clear()
      Cookies.remove('token')
      return
   }
   return res
},err=>{
      Message.error('服务器内部错误')
      return Promise.reject(err);
})

export default request